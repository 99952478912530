
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { useRoute } from 'vue-router'
import { useConfirm } from '@/composables/useConfirm'
import { IPhoto } from '@/interfaces/IPhotos'
import localforage from 'localforage'
import 'swiper/swiper.scss'

export default defineComponent({
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const { initConfirm } = useConfirm()
        const inputFile = ref()
        const file = ref()
        const photos = ref<IPhoto[]>([])
        const activePhoto = ref(0)
        const route = useRoute()

        const swiper = ref<any>(null)
        const swiperSlides = computed(() => {
            return swiper.value?.slides
        })
        const setSwiper = (swiperObject: any) => {
            swiper.value = swiperObject
        }

        const takePhoto = () => {
            inputFile.value.click()
        }

        const confirmAccept = async () => {
            photos.value.splice(activePhoto.value, 1)
            activePhoto.value = 0
        }

        const removePhoto = (idx: number) => {
            activePhoto.value = idx
            initConfirm('Czy na pewno chcesz usunąć wybrane zdjęcie?', confirmAccept, undefined)
        }

        const swiperBreakpoints = computed(() => {
            const pictures = photos.value.length
            return {
                640: {
                    slidesPerView: pictures === 2 ? 2 : 1,
                },
                780: {
                    slidesPerView: pictures > 2 ? 3 : pictures === 2 ? 2 : 1,
                },
            }
        })

        const uploadPhoto = (el: any) => {
            const selectedFile = el.target.files[0]
            const reader = new FileReader()

            reader.readAsDataURL(selectedFile)
            reader.onload = function (event: any) {
                photos.value.push({
                    url: event.target.result,
                })
            }
        }

        watch(swiperSlides, () => {
            localforage.setItem('deliveryPhotos', {
                deliveryId: +route.params.id,
                photos: JSON.parse(JSON.stringify(photos.value)),
            })
            if (swiperSlides.value && swiperSlides.value.length) {
                swiper.value.slideNext()
            }
        })

        onMounted(async () => {
            const storagePhotos: any | null = await localforage.getItem('deliveryPhotos')
            if (storagePhotos) {
                photos.value = storagePhotos.photos
            }
        })

        return {
            file,
            inputFile,
            photos,
            removePhoto,
            swiperBreakpoints,
            setSwiper,
            takePhoto,
            uploadPhoto,
        }
    },
})
