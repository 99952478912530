
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAuth } from '@/composables/useAuth'
import localforage from 'localforage'
import { IDeliveryNotes, IDeliveryPhotos, ITempComment } from '@/interfaces/IDelivery'
import { useDelivery } from '@/composables/useDelivery'
import { useConfirm } from '@/composables/useConfirm'
import MDeliveryPhotos from '@/components/MDeliveryPhotos.vue'
import { ERoles } from '@/enums/roles'
import { IPhoto } from '@/interfaces/IPhotos'

export default defineComponent({
    components: {
        MDeliveryPhotos,
    },
    setup() {
        const { role } = useAuth()
        const router = useRouter()
        const route = useRoute()
        const {
            setDeliveryNote,
            getCurrentDeliveryNoteType: noteType,
            currentDelivery: delivery,
        } = useDelivery()
        const initialComment = ref()
        const initialPhotos = ref<IPhoto[]>([])
        const comment = ref()
        const { initConfirm } = useConfirm()

        onMounted(async () => {
            const deliveryPhotos: IDeliveryPhotos | null = await localforage.getItem(
                'deliveryPhotos',
            )
            if (deliveryPhotos) {
                initialPhotos.value = deliveryPhotos.photos
            }
            if (role.value === ERoles.Courier) {
                const deliveryActiveAction: any = await localforage.getItem('deliveryActiveAction')
                if (
                    !deliveryActiveAction ||
                    (deliveryActiveAction &&
                        +deliveryActiveAction.currentDeliveryId !== +route.params.id)
                ) {
                    router.push({ name: 'DeliveryDetails' + role.value })
                }

                if (delivery.value) {
                    comment.value =
                        delivery.value[('note' + noteType.value) as keyof IDeliveryNotes]
                    initialComment.value =
                        delivery.value[('note' + noteType.value) as keyof IDeliveryNotes]
                }
            } else if (role.value === ERoles.Manager) {
                const savedComment: ITempComment | null = await localforage.getItem('tempComment')
                if (savedComment) {
                    comment.value = savedComment.comment
                    initialComment.value = savedComment.comment
                }
            }
        })

        const addComment = async () => {
            setDeliveryNote(noteType.value, comment.value)
            goBack(true)
        }
        const goBack = async (saveInStorage = false) => {
            if (saveInStorage) {
                const tempComment: ITempComment = {
                    comment: comment.value,
                    id: route.params.id,
                }
                await localforage.setItem('tempComment', tempComment)
            } else {
                await localforage.setItem('deliveryPhotos', {
                    deliveryId: +route.params.id,
                    photos: JSON.parse(JSON.stringify(initialPhotos.value)),
                })
            }
            if (role.value === ERoles.Courier) {
                router.push({
                    name: 'DeliveryDetailsCourier',
                })
            } else if (role.value === ERoles.Manager) {
                if (route.params.name === 'CarWashStock') {
                    router.push({
                        name: 'CarWashStock',
                        params: { id: route.params.id },
                    })
                } else if (route.params.name === 'CarWashDemand') {
                    router.push({
                        name: 'CarWashDemand',
                        params: { id: route.params.id },
                    })
                } else if (route.params.name === 'DeliveryDetailsManager') {
                    router.push({
                        name: 'DeliveryDetailsManager',
                        params: { id: route.params.id },
                    })
                }
            }
        }

        const cancelComment = async () => {
            const deliveryPhotos: IDeliveryPhotos | null = await localforage.getItem(
                'deliveryPhotos',
            )
            if (
                comment.value !== initialComment.value ||
                (deliveryPhotos && initialPhotos.value !== deliveryPhotos.photos)
            ) {
                initConfirm(
                    'Jeśli opuścisz ekran dodawania komentarza, wprowadzone zmiany zostaną utracone. Jesteś pewien?',
                    goBack,
                    undefined,
                )
            } else {
                goBack()
            }
        }

        return {
            addComment,
            cancelComment,
            comment,
            ERoles,
            role,
        }
    },
})
